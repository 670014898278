* {
    box-sizing: border-box;
}

html {
    --background-color: #ffffff;
    --background-image: none;
    --body-color: #344055;
    --accent-color: rgb(0, 116, 232);

    --error-color: rgb(255, 73, 92);
    --success-color: rgb(61, 220, 151);

    --button-text-color: #ffffff;
    --button-background-color: var(--accent-color);

    --button-option-text-color: #111;
    --button-option-background-color: #e2e2e2;

    --input-border: #d6d9dd;

    --background-overlay: linear-gradient(90deg,
    rgba(255, 255, 255, .2) 0%,
    rgba(255, 255, 255, .2) 100%);

    --seat-background-color: rgb(111, 119, 134);
    --seat-text-color: white;

    --card-background-color: rgba(255, 255, 255, .4);

    &.dark {
        --background-color: rgb(16, 30, 30);
        --body-color: #ffffff;

        --input-border: #344055;

        --button-option-text-color: #ffffff;
        --button-option-background-color: #2a3334;

        --background-overlay: linear-gradient(90deg,
        rgba(16, 30, 30, .6) 0%,
        rgba(16, 30, 30, .6) 100%);

        --seat-background-color: rgb(153, 159, 170);

        --card-background-color: rgba(16, 30, 30, .4);
    }
}

body {
    margin: 0;
    height: 100vh;

    font-family: 'Inter', sans-serif;

    color: var(--body-color);

    background: var(--background-overlay, var(--background-color)), var(--background-image);
    background-color: var(--background-color);

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

h1, h2, h3 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    text-wrap: balance;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2.4rem;
}

button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 2px;
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    white-space: nowrap;

    &:not(:disabled) {
        cursor: pointer;
    }

    &.full {
        width: 100%;
        height: 100%;
    }

    &.secondary, &.option {
        background: var(--button-option-background-color);
        color: var(--button-option-text-color);
    }

    &.option.selected {
        background: var(--accent-color);
        color: var(--button-text-color);
    }
}

input, textarea, select, button {
    font: inherit;
}

input, textarea, select {
    padding: .5rem;
    border: 2px solid var(--input-border);
    background: var(--background-color);
    color: var(--body-color);
}

label:has(input[type="text"]) {
    display: block;
    margin-bottom: 1rem;

    span {
        display: block;
        padding-inline-start: 4px;
        padding-block-end: 2px;
    }

    input {
        width: 100%;
    }

    &:has([required]) span::after {
        content: " *";
        color: var(--accent-color);
    }
}

.row {
    display: block;
}

.ng-invalid.ng-touched {
    border-color: var(--error-color);
    outline-color: var(--error-color);

    .label::after {
        color: var(--error-color);
    }
}

label:has(input[type="checkbox"][required]) {
    .label::before {
        content: "*";
        color: var(--accent-color);
        display: inline;
        margin-right: 0.2rem;
    }

    &:has(.ng-invalid.ng-touched) {
        .label::after {

            color: var(--error-color);
        }
    }
}

button[disabled] {
    opacity: .6;
}

.text-center {
    text-align: center;
}

input[type="checkbox"] {
    max-height: 14px;
}

a {
    color: var(--accent-color);
    text-decoration: none;
}

dialog {
    width: 45ch;
    padding: 2rem;
    border: 2px solid var(--input-border);
    background: var(--background-color);
    color: var(--body-color);
    border-radius: 2px;

    footer {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
    }
}

.dim {
    opacity: .68;
}

.cm-branding {
    position: fixed;
    top: 1rem;
    padding: 0 1rem;
    text-align: right;
    width: 100%;
    height: 32px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
        height: 32px;
    }

    a:not(:last-child) {
        margin-inline-end: 1rem;
    }
}

input {
    accent-color: var(--accent-color);
    outline-color: var(--accent-color);
}

@media (min-width: 900px) {
    html {
        --background-overlay: linear-gradient(90deg,
        rgba(255, 255, 255, .2) 0%,
        rgba(255, 255, 255, .2) 100%);

        &.dark {
            --background-overlay: linear-gradient(90deg,
            rgba(16, 30, 30, .1) 0%,
            rgba(16, 30, 30, .6) 25%,
            rgba(16, 30, 30, .6) 75%,
            rgba(16, 30, 30, .1) 100%);
        }
    }

    .row {
        display: flex;
        gap: 1rem;
        width: 100%;

        > * {
            flex-grow: 1;
        }
    }
}

[popover] {
    background: var(--background-color);
    color: var(--body-color);
    border: 2px solid var(--input-border);

    &::backdrop {
        background-color: rgba(0, 0, 0, .4);
    }
}

button:has(svg) {
    display: flex;
    align-items: center;

    svg {
        margin-inline-end: .5rem;
    }
}

.text-right {
    text-align: right;
}

details {
    border-block: 1px solid var(--body-color);
    padding: 1rem;
}

summary {
    list-style: none;
}

details[open] {
    label {
        padding-block-start: 1rem;
    }
}

label:has(input[type=text]).with-button {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: min-content 1fr;
    grid-column-gap: .5rem;

    .label {
        grid-area: 1 / 1 / 2 / 3;
    }
}

dialog {
    .title {
        font-weight: 700;
        font-size: 1.1rem;
    }

    .body {
        margin-block: 1rem;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .error-code {
        opacity: .7;
    }
}
